import React, { useRef } from "react";

import { MDXRenderer } from "gatsby-plugin-mdx";
import PropTypes from "prop-types";
import Nav from "react-bootstrap/Nav";

import useToggle from "hooks/useToggle";

const TopicContentHidden = ({ hideContent, topicContent }) => {
  const [allVisible, toggleAllVisible] = useToggle(false);
  const maskRef = useRef();

  const handleClick = () => {
    maskRef.current.classList.toggle("map-topic-text-visible");
    toggleAllVisible();
  };

  if (!hideContent) {
    return (
      <>
        {topicContent && (
          <MDXRenderer name={topicContent.node.name}>
            {topicContent.node.childMdx.body}
          </MDXRenderer>
        )}
      </>
    );
  }

  return (
    <>
      <div
        ref={maskRef}
        className="position-relative overflow-hidden map-topic-text"
      >
        <div className="position-absolute w-100 h-100 map-topic-mask" />
        {topicContent && (
          <MDXRenderer name={topicContent.node.name}>
            {topicContent.node.childMdx.body}
          </MDXRenderer>
        )}
      </div>
      <Nav.Link
        onClick={handleClick}
        className="pl-0 pt-0 read-more small font-weight-bold"
      >
        {allVisible ? "Read less" : "...Read More"}
      </Nav.Link>
    </>
  );
};

TopicContentHidden.propTypes = {
  hideContent: PropTypes.bool.isRequired,
  topicContent: PropTypes.object.isRequired,
};

export default TopicContentHidden;
