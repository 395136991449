import React from "react";

import PropTypes from "prop-types";
import { ArrowLeftCircleFill } from "react-bootstrap-icons";
import Nav from "react-bootstrap/Nav";

import { initialMapState } from "./types";
import withMapActions from "./withMapActions";

const ExitContentLink = (props) => {
  const { resetMapState, initialMapState, toggleVisible, clearTopic } = props;

  const handleClick = () => {
    toggleVisible();
    clearTopic();
    resetMapState(initialMapState);
  };

  return (
    <Nav.Link onClick={handleClick} className="text-light">
      <ArrowLeftCircleFill size={25} />
    </Nav.Link>
  );
};

ExitContentLink.propTypes = {
  resetMapState: PropTypes.func.isRequired,
  initialMapState: initialMapState,
  toggleVisible: PropTypes.func.isRequired,
  clearTopic: PropTypes.func.isRequired,
};

export default withMapActions(ExitContentLink);
