import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import PageLoadingOverlay from "components/Page/PageLoadingOverlay";
import { setActiveSceneId } from "state/actions";

import HotspotRenderer from "./HotspotRenderer";

/**
 * Build the hotspots and place them in the hotspotContainer.
 *
 * @param hotspots a list of hotspot configurations.
 * @param hotspotContainer a `Marzipano.HotspotContainer` instance.
 * @param pulsateHotspots bool, whether to add the pulse-hotspot animation.
 *
 * Hotspots are sourced from hidden DOM elements using unique ids. An element
 * with the given `elementId` is expected to exist and will be used for the content
 * of the hotspot pop-up. These elements are expected to be self contained.
 *
 * A `Hotspot` container exists for rendering simple hotspots with a trigger icon.
 */
const addHotspots = (hotspots, hotspotContainer, pulsateHotspots) => {
  hotspots.forEach((config) => {
    const hotspotSource = document.getElementById(config.elementId);
    // Replace any existing classes from the source wrapper otherwise it breaks
    // formatting when loaded into the Canvas.
    hotspotSource.className = pulsateHotspots ? "pulsing-hotspot" : "";
    hotspotContainer.createHotspot(hotspotSource, config.position, {
      perspective: { extraTransforms: "translate(-50%, -50%)" },
    });
  });
};

/**
 * Configure the scene and add the hotspots to the panorama viewer.
 *
 * @param Marzipano the Marzipano library. Importing this as a module fails during
 *   the Gatsby build process, requiring it to be provided using `require` on mount.
 * @param sceneId string, the id of the scene. This id is also used as the folder
 *   name for the scene's tile images.
 * @param geometries the geometries available to the `CubeGeometry`.
 * @param maxResolution the max resolution for the view limiter.
 * @param maxVFov the max vertical field of view for the view limiter.
 * @param maxHFov the max horizontal field of view for the view limiter.
 * @param viewParams the parameters for the `RectilinearView`.
 * @param viewer the panorama viewer instance.
 * @returns {Scene}
 */
const createScene = (
  Marzipano,
  sceneId,
  geometries,
  maxResolution,
  maxVFov,
  maxHFov,
  viewParams,
  viewer
) => {
  const source = Marzipano.ImageUrlSource.fromString(
    `/tiles/${sceneId}/{z}/{f}/{y}/{x}.jpg`,
    { cubeMapPreviewUrl: `/tiles/${sceneId}/preview.jpg` }
  );
  const geometry = new Marzipano.CubeGeometry(geometries);
  const limiter = Marzipano.RectilinearView.limit.traditional(
    maxResolution,
    maxVFov,
    maxHFov
  );
  const view = new Marzipano.RectilinearView(viewParams, limiter);
  return viewer.createScene({
    source,
    geometry,
    view,
    pinFirstLevel: true,
  });
};

/**
 * Print the yaw and pitch coordinates for a click event's position on the panorama.
 *
 * Used in debug mode to determine the location of info spots.
 * @param panoramaViewer the panorama viewer object which converts the x/y
 *   coords to the pitch and yaw.
 * @param event
 */
const printClickCoordinates = (panoramaViewer, event) => {
  if (panoramaViewer) {
    console.debug(
      panoramaViewer
        .view()
        .screenToCoordinates({ x: event.clientX, y: event.clientY })
    );
  } else {
    console.debug("No viewer has loaded yet.");
  }
};

const VirtualConsultationRoom = (props) => {
  const dispatch = useDispatch();
  const activeSceneId = useSelector((state) => state.vcrScenes.activeSceneId);

  const [scenes, setScenes] = useState({});
  const [currentSceneId, setCurrentSceneId] = useState("");
  const [hasLoaded, setHasLoaded] = useState(false);
  const [panoramaViewer, setPanoramaViewer] = useState(null);

  const panoramaId = `virtual-consultation-room-${props.name}`;

  useEffect(() => {
    if (!hasLoaded && typeof window !== "undefined") {
      // Only mount the panorama in production when the window object exists, to
      // avoid issues during Gatsby's build step.
      const Marzipano = require("marzipano");

      // Create the viewer and add it to the state for debug purposes.
      const viewer = new Marzipano.Viewer(document.getElementById(panoramaId));
      props.debug && setPanoramaViewer(viewer);

      // Create each scene and load the first scene.
      props.scenes.forEach((sceneConfig, index) => {
        const scene = createScene(
          Marzipano,
          sceneConfig.id,
          sceneConfig.geometries,
          sceneConfig.limiter.maxResolution,
          sceneConfig.limiter.maxVFov,
          sceneConfig.limiter.maxHFov,
          sceneConfig.viewParams,
          viewer
        );

        // Attach the hotspots to the scene, so they aren't shown when scenes
        // are changed.
        addHotspots(
          sceneConfig.hotspots,
          scene.hotspotContainer(),
          sceneConfig.pulsateHotspots
        );

        setScenes((existingScenes) => ({
          ...existingScenes,
          [sceneConfig.id]: scene,
        }));

        if (index === 0) {
          scene.switchTo();
          setCurrentSceneId(sceneConfig.id);
        }
      });

      dispatch(setActiveSceneId(currentSceneId));
      setHasLoaded(true);
    }

    // Change scene if the current scene in the local state doesn't match the desired
    // active scene in the Redux store (set by a hotspot click action).
    if (
      hasLoaded &&
      typeof window !== "undefined" &&
      activeSceneId &&
      currentSceneId !== activeSceneId
    ) {
      const targetScene = scenes[activeSceneId];
      targetScene.switchTo();
      setCurrentSceneId(activeSceneId);
    }
  });

  const currentScene = props.scenes.find(
    (scene) => scene.id === currentSceneId
  );
  const currentDisclaimer = currentScene && currentScene.disclaimer;

  return (
    <>
      <PageLoadingOverlay loading={!hasLoaded} />
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions*/}
      <div
        id={panoramaId}
        className="virtual-consultation-room-container"
        onClick={
          props.debug
            ? (event) => printClickCoordinates(panoramaViewer, event)
            : undefined
        }
      />
      <HotspotRenderer />

      {currentDisclaimer && (
        <span className="virtual-consultation-room-disclaimer small">
          {currentDisclaimer}
        </span>
      )}
    </>
  );
};

VirtualConsultationRoom.propTypes = {
  debug: PropTypes.bool,
  scenes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      disclaimer: PropTypes.string,
      geometries: PropTypes.arrayOf(PropTypes.object).isRequired,
      limiter: PropTypes.shape({
        maxResolution: PropTypes.number.isRequired,
        maxVFov: PropTypes.number.isRequired,
        maxHFov: PropTypes.number.isRequired,
      }).isRequired,
      viewParams: PropTypes.object.isRequired,
      hotspots: PropTypes.arrayOf(
        PropTypes.shape({
          elementId: PropTypes.string.isRequired,
          position: PropTypes.shape({
            pitch: PropTypes.number.isRequired,
            yaw: PropTypes.number.isRequired,
          }).isRequired,
        }).isRequired
      ),
      pulsateHotspots: PropTypes.bool,
    })
  ).isRequired,
};

VirtualConsultationRoom.defaultProps = {
  debug: false,
};

export default VirtualConsultationRoom;
