import React, { useState, useCallback } from "react";

import _throttle from "lodash/throttle";
import PropTypes from "prop-types";
import { Globe, Map } from "react-bootstrap-icons";
import ReactDOM from "react-dom";

const TiltButton = ({ map }) => {
  const initialPitch = map.getPitch();
  const [is2D, setis2D] = useState(initialPitch === 0);

  const updatePitch = useCallback(
    _throttle(() => {
      const pitch = map.getPitch();
      setis2D(pitch === 0);
    }, 400),
    []
  );

  map.on("pitch", updatePitch);

  const handleClick = () => {
    if (is2D) {
      map.setPitch(60);
    } else {
      map.setPitch(0);
    }
  };

  return (
    <button
      type="button"
      className="font-weight-bold"
      aria-label="Toggle between 2D and 3D view"
      onClick={handleClick}
    >
      {is2D ? <Globe size={18} /> : <Map size={18} />}
    </button>
  );
};

TiltButton.propTypes = {
  map: PropTypes.object.isRequired,
};

export class TiltControl {
  onAdd(map) {
    const containerNode = document.createElement("div");
    this._map = map;
    this._container = containerNode;
    this._container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
    ReactDOM.render(<TiltButton map={map} />, containerNode);
    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }
}
