import PropTypes from "prop-types";

export const layerOptions = PropTypes.shape({
  visible: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
}).isRequired;

export const topicLayerControl = PropTypes.shape({
  ids: PropTypes.arrayOf(PropTypes.string.isRequired),
  label: PropTypes.string.isRequired,
}).isRequired;

export const topicTitle = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  locationOptions: PropTypes.object.isRequired,
  layerOptions: layerOptions,
};

const topicContent = {
  ...topicTitle,
  content: PropTypes.shape({
    elementId: PropTypes.string.isRequired,
    layerControls: PropTypes.arrayOf(topicLayerControl).isRequired,
  }).isRequired,
};

export const topics = PropTypes.arrayOf(PropTypes.shape(topicContent))
  .isRequired;

export const initialMapState = PropTypes.shape({
  locationOptions: PropTypes.object.isRequired,
  layerOptions: layerOptions,
});

const basemapConfig = PropTypes.shape({
  imageName: PropTypes.string.isRequired,
  layerId: PropTypes.string.isRequired,
}).isRequired;

export const basemaps = PropTypes.shape({
  primary: basemapConfig,
  alternate: basemapConfig,
}).isRequired;
