import React from "react";

import PropTypes from "prop-types";

import TopicLayerSelector from "./TopicLayerSelector";
import { topicLayerControl } from "./types";

const TopicLayerSelectGroup = ({ layerControls, elementId }) => {
  const controls = layerControls.map((layerControl, index) => {
    return (
      <TopicLayerSelector key={`${elementId}-${index}`} {...layerControl} />
    );
  });

  return (
    <div className="mb-2">
      <h6>Map Layers</h6>
      {controls}
    </div>
  );
};

TopicLayerSelectGroup.propTypes = {
  layerControls: PropTypes.arrayOf(topicLayerControl).isRequired,
  elementId: PropTypes.string.isRequired,
};

export default TopicLayerSelectGroup;
