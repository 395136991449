import React, { useState, useContext, useEffect } from "react";

import PropTypes from "prop-types";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";

import { MapContext, types } from "./MapContext";
import withMapActions from "./withMapActions";

const LayerItem = (props) => {
  const { ids, label, initialVisibleLayers, setLayersVisibility } = props;
  const [mapState, setMapState] = useContext(MapContext);
  const initialVisibility = ids.every((id) =>
    initialVisibleLayers.includes(id)
  );
  const [checked, toggleChecked] = useState(initialVisibility);

  useEffect(() => {
    if (mapState.mapResetTrigger !== 0) {
      const visibleLayers = initialVisibleLayers.concat(
        mapState.userSelectedLayers
      );
      const itemVisible = ids.every((id) => {
        return visibleLayers.includes(id);
      });
      toggleChecked(itemVisible);
    }
  }, [mapState.mapResetTrigger]);

  const handleClick = () => {
    const visible = !checked;
    toggleChecked(visible);
    setLayersVisibility(ids, visible);
    if (visible) {
      setMapState({ type: types.ADD_USER_SELECTED_LAYERS, payload: ids });
    } else {
      setMapState({ type: types.REMOVE_USER_SELECTED_LAYERS, payload: ids });
    }
  };

  return (
    <Dropdown.Item
      as="button"
      onClick={handleClick}
      style={{ width: "100%" }}
      className="pl-2 pt-1 pb-1 text-wrap small map-control-dropdown-item"
    >
      <Form.Check type="checkbox" label={label} checked={checked} readOnly />
    </Dropdown.Item>
  );
};

LayerItem.propTypes = PropTypes.shape({
  ids: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  label: PropTypes.string.isRequired,
  initialVisibleLayers: PropTypes.arrayOf(PropTypes.string).isRequired,
  setLayersVisibility: PropTypes.func.isRequired,
}).isRequired;

export default withMapActions(LayerItem);
