import React from "react";

import PropTypes from "prop-types";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import Button from "react-bootstrap/Button";

import useToggle from "hooks/useToggle";

import withMapActions from "./withMapActions";

const TopicLayerSelector = (props) => {
  const { label, ids, setLayersVisibility } = props;
  const [visible, toggleVisible] = useToggle(true);

  const handleClick = () => {
    setLayersVisibility(ids, !visible);
    toggleVisible();
  };

  return (
    <Button
      onClick={handleClick}
      variant="outline-primary"
      className="text-left"
      block
    >
      <span className="toggle-button-icon">
        {visible ? <Eye size={18} /> : <EyeSlash size={18} />}
      </span>{" "}
      <span className="small">{label}</span>
    </Button>
  );
};

TopicLayerSelector.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  label: PropTypes.string.isRequired,
  setLayersVisibility: PropTypes.func.isRequired,
};

export default withMapActions(TopicLayerSelector);
