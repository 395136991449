/**
 * Render all Mapbox popups for the interactive map.
 *
 * This component will render all popups defined in app-config in a hidden
 * parent div. The popups are appended to the mapbox popup element on selection
 * and returned to the parent div when the mapbox popup is closed.
 */

import React from "react";

import PropTypes from "prop-types";

import LayerPopups from "./LayerPopups";
import { popupContainerId } from "./popups";
import { popups } from "./types";

const PopupContainer = ({ popups, popupRef }) => {
  const popupElements = popups.map((popup) => {
    const { id, featureProps, layerId } = popup;
    return (
      <LayerPopups
        key={id}
        featureProps={featureProps}
        popupRef={popupRef}
        layerId={layerId}
      />
    );
  });

  return (
    <div id={popupContainerId} className="d-none">
      {popupElements}
    </div>
  );
};

PopupContainer.propTypes = {
  popups: popups,
  popupRef: PropTypes.object.isRequired,
};

export default PopupContainer;
