/**
 * An ImageLink is a simple image that can be clicked to access an external or
 * internal page. Text can be optionally placed below the image.
 *
 * Images should be placed in `images/links` to be accessible by this component.
 * The `fileName` prop is used to select the appropriate image (Gatsby/Graphql
 * queries don't support interpolation to place the filename in the query during
 * compile time.)
 */

import React from "react";

import Img from "gatsby-image";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import Link from "components/Links/Link";

import { requiredLinkPropsCheck, withImageData } from "./common";

const ImageLink = withImageData((props) => {
  const image = props.imageData.images.edges.find(({ node }) => {
    return node.name === props.fileName;
  });

  const ImageTag = image ? (
    <Img
      fluid={image.node.childImageSharp.fluid}
      alt={image.node.name}
      style={props.imgStyle}
    />
  ) : (
    <>{props.fileName}</>
  );

  return (
    <Row
      className={
        props.alignCenter ? "align-items-center flex-column" : "flex-column"
      }
    >
      <Col
        xs={12}
        className={
          props.alignCenter &&
          "d-flex align-items-center justify-content-center"
        }
      >
        <Link {...props.url}>{ImageTag}</Link>
      </Col>

      {props.belowLinkText && (
        <Col xs={12}>
          <p
            className={props.alignCenter && "text-center"}
            style={props.textStyle}
          >
            {props.belowLinkText}
          </p>
        </Col>
      )}
    </Row>
  );
});

ImageLink.propTypes = {
  alignCenter: PropTypes.bool,
  fileName: PropTypes.string.isRequired,
  belowLinkText: PropTypes.string,
  imgStyle: PropTypes.object,
  textStyle: PropTypes.object,
  url: PropTypes.shape({
    path: requiredLinkPropsCheck,
    targetModalId: requiredLinkPropsCheck,
    isExternal: PropTypes.bool,
  }).isRequired,
};

export default ImageLink;
