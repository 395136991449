import React from "react";

import PropTypes from "prop-types";
import { ArrowRightCircleFill } from "react-bootstrap-icons";
import Button from "react-bootstrap/Button";

import { topicTitle } from "./types";
import withMapActions from "./withMapActions";

const TopicTitle = (props) => {
  const {
    id,
    title,
    showContent,
    setTopicId,
    locationOptions,
    setMapState,
    layerOptions,
  } = props;

  const handleSetTopic = () => {
    setTopicId(id);
    showContent();
    setMapState(locationOptions, {
      layerIds: layerOptions.visible,
      isVisible: true,
    });
  };

  return (
    <Button
      onClick={handleSetTopic}
      block
      className="topic-title p-3 px-md-2 p-lg-3"
      variant="outline-primary"
    >
      <div className="d-flex justify-content-between align-items-center">
        <h6 className="m-0 text-left">{title}</h6>
        <ArrowRightCircleFill
          className="topic-select-icon flex-shrink-0"
          size={20}
        />
      </div>
    </Button>
  );
};

TopicTitle.propTypes = {
  ...topicTitle,
  showContent: PropTypes.func.isRequired,
  setTopicId: PropTypes.func.isRequired,
  setMapState: PropTypes.func.isRequired,
};

export default withMapActions(TopicTitle);
