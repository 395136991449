/**
 * Mapbox layers can be styled with a manually defined style object or the style object can be
 * requested from mapbox studio using the username and style id. User defined properties will
 * override those fetched from mapbox studio.
 */

import axios from "axios";
import _has from "lodash/has";

// On error use mapbox default styles except fill, mapbox default is solid black.
const defaultStyle = {
  paint: {
    "fill-color": "rgba(108, 122, 137, 0.8)",
    "fill-outline-color": "#000000",
  },
};

const getStyleDefinition = (username, styleId) => {
  const token = process.env.GATSBY_MAPBOX_ACCESS_TOKEN;
  const url = `https://api.mapbox.com/styles/v1/${username}/${styleId}?access_token=${token}`;
  return axios
    .get(url)
    .then((response) => {
      return response.data.layers;
    })
    .catch(() => {
      return [];
    });
};

const getStyleParams = (layer) => {
  if (!layer.mapboxStyle) {
    return;
  }
  const [username, styleId] = layer.mapboxStyle.split(/[/]/).slice(-2);
  return { username, styleId };
};

// One mapbox style can have multiple layer style definitions. Only want to request the style once to
// avoid unnecessary API requests.
const mapboxStyleRequired = (layers) => {
  return layers.reduce((accumulator, layer) => {
    const styleParams = getStyleParams(layer);
    if (_has(styleParams, "styleId")) {
      const { username, styleId } = styleParams;
      accumulator[styleId] = { username };
    }
    return accumulator;
  }, {});
};

const getLayerStyles = async (layers) => {
  if (sessionStorage.getItem("mapboxStyles")) {
    return;
  }
  const styles = mapboxStyleRequired(layers);
  const styleDefinitions = Object.keys(styles).map(async (styleId) => {
    const { username } = styles[styleId];
    styles[styleId].layers = await getStyleDefinition(username, styleId);
  });
  await Promise.all(styleDefinitions);
  sessionStorage.setItem("mapboxStyles", JSON.stringify(styles));
  return;
};

const styleLookup = (layer) => {
  const styleParams = getStyleParams(layer);
  if (_has(styleParams, "styleId")) {
    const styles = JSON.parse(sessionStorage.getItem("mapboxStyles"));
    const layerStyles = styles[styleParams.styleId].layers;
    const layerStyle = layerStyles.filter((layerStyle) => {
      return layerStyle.id === layer.id;
    })[0];
    return layerStyle || (layer.type === "fill" ? defaultStyle : {});
  }
};

const addLayer = (map, layer) => {
  const { id, source, ...properties } = layer;
  const layerProperties = {
    ...properties,
    source: id,
    id: id,
  };
  map.addSource(id, source);
  map.addLayer(layerProperties);
};

export const addLayersToMap = async (map, layers) => {
  await getLayerStyles(layers);
  layers.forEach((layer) => {
    const style = styleLookup(layer);
    addLayer(map, { ...style, ...layer });
  });
  return;
};

// Check if map has loaded by running map.loaded() at
// specified interval recursively
export const mapLoaded = (map) => {
  let checkCount = 0;
  const maxChecks = 150;
  const checkInterval = 200;

  return new Promise((resolve) => {
    const intervalId = setInterval(() => {
      checkCount++;
      if (map.loaded() || checkCount >= maxChecks) {
        resolve();
        clearInterval(intervalId);
        return;
      }
    }, checkInterval);
  });
};
