/**
 * A Higher Order Component that wraps a component with mapbox actions.
 * Context provider allows access to map instance being used.
 *
 * Adds two props to the wrapped component:
 * - zoomToLocation: Zoom to a location on the map.
 * - setLayersVisibility: Make a layer visible or hidden.
 *
 */

import React, { useContext } from "react";

import _flatten from "lodash/flatten";
import _has from "lodash/has";
import _isEmpty from "lodash/isEmpty";

import { mapConfig } from "src/app-config";

import { MapContext, types } from "../MapContext";

export const getVisibleLayerLegendItems = (visibleLayerIds) => {
  const legendsForVisibleLayers = [];
  Object.keys(mapConfig.layerLegends).forEach((key) => {
    if (visibleLayerIds.includes(key)) {
      const legendItems = mapConfig.layerLegends[key].map((legend) => {
        return {
          ...legend,
          id: key,
        };
      });
      legendsForVisibleLayers.push(legendItems);
    }
  });
  return _flatten(legendsForVisibleLayers);
};

export const setInitialVisibleLayers = (map, visibleLayerIds) => {
  const { layers } = map.getStyle();
  layers.forEach((layer) => {
    if (!_has(layer, "metadata.mapbox:featureComponent")) {
      const isVisible = visibleLayerIds.includes(layer.id);
      const visibility = isVisible ? "visible" : "none";
      map.setLayoutProperty(layer.id, "visibility", visibility);
    }
  });
};

const setfeatureComponentVisibility = (map, visibility) => {
  const { layers } = map.getStyle();
  layers.forEach((layer) => {
    if (_has(layer, "metadata.mapbox:featureComponent")) {
      map.setLayoutProperty(layer.id, "visibility", visibility);
    }
  });
};

export default function withMapboxActions(WrappedComponent) {
  return function WrappedwithMapboxActions(props) {
    const [mapContext, setMapContext] = useContext(MapContext);

    /**
     * Zoom to a location on the map.
     * @param locationOptions an object with mapbox camera options as specified by the
     * mapbox-gl-js API, all properties are optional
     * https://docs.mapbox.com/mapbox-gl-js/api/properties/#cameraoptions
     */
    const zoomToLocation = (locationOptions) => {
      mapContext.map.flyTo(locationOptions);
    };

    /**
     * Set map layers visibility.
     * @param layerIds array of unique identifiers to select layers from the map instance.
     * @param isVisible boolean to set visibility of layers.
     */
    const setLayersVisibility = (layerIds, isVisible) => {
      const visibilty = isVisible ? "visible" : "none";
      layerIds.forEach((layerId) => {
        mapContext.map.setLayoutProperty(layerId, "visibility", visibilty);
      });
    };

    /**
     * Set the map legend to match the list of layer ids.
     * @param layerIds array of unique identifiers to select legend items from the map config.
     */
    const setMapLegend = (layerIds) => {
      const legendItems = getVisibleLayerLegendItems(layerIds);
      setMapContext({ type: types.SET_MAP_LEGEND, payload: legendItems });
    };

    /**
     * Set map layers visibility and map view.
     * @param locationOptions array of unique identifiers to select layers from the map instance.
     * @param layerOptions object with layerIds and isVisible properties.
     */
    const setMapState = (locationOptions, layerOptions) => {
      const { layerIds, isVisible } = layerOptions;
      if (layerIds.length !== 0) {
        setLayersVisibility(layerIds, isVisible);
        setMapLegend(layerIds);
      }
      if (!_isEmpty(locationOptions)) {
        zoomToLocation(locationOptions);
      }
    };

    /**
     * Mapbox doesn't really have "basemaps", the style encapsulates all layers and contextual data (basemap).
     * We require a basemap toggle so are adding a raster layer that can be toggled on or off. When this alternate
     * raster is toggled on we turn off all mapbox base layers in our default style.
     *
     * @param alternateId, the id of the alternate basemap.
     * @param showAlternate bool, whether to show the alternate base layer or not.
     */
    const showAlternateBasemap = (alternateId, showAlternate) => {
      const alternateVisibility = showAlternate ? "visible" : "none";
      const featureVisibility = showAlternate ? "none" : "visible";
      setfeatureComponentVisibility(mapContext.map, featureVisibility);
      mapContext.map.setLayoutProperty(
        alternateId,
        "visibility",
        alternateVisibility
      );
      // Do not automatically turn basemap on/off if user has selected it
      if (showAlternate) {
        setMapContext({
          type: types.ADD_USER_SELECTED_LAYERS,
          payload: [alternateId],
        });
      } else {
        setMapContext({
          type: types.REMOVE_USER_SELECTED_LAYERS,
          payload: [alternateId],
        });
      }
    };

    /**
     * Reset map state to initial view. Visible layers will include those set in initialMapState
     * and those selected by the user.
     * @param initialMapState object with locationOptions and layerOptions properties
     */
    const resetMapState = (initialMapState) => {
      const { locationOptions, layerOptions } = initialMapState;
      setMapContext({ type: types.RESET_MAP });
      // We do not want to turn off user selected items on map reset.
      const visibleLayers = mapContext.userSelectedLayers.concat(
        layerOptions.visible
      );
      setInitialVisibleLayers(mapContext.map, visibleLayers);
      setMapLegend(visibleLayers);
      zoomToLocation(locationOptions);
    };

    /**
     * Switch the map to a new topic and remove topic layers for the current topic.
     * @param locationOptions object with locationOptions to zoom to
     * @param layerOptions object with layerOptions to turn on
     * @param hideLayers array of layer ids to turn off
     */
    const setNewMapTopic = (locationOptions, layerOptions, hideLayers) => {
      setMapState(locationOptions, layerOptions);
      setLayersVisibility(hideLayers, false);
      setMapLegend(layerOptions.layerIds);
    };

    return (
      <WrappedComponent
        zoomToLocation={zoomToLocation}
        setLayersVisibility={setLayersVisibility}
        showAlternateBasemap={showAlternateBasemap}
        setMapState={setMapState}
        resetMapState={resetMapState}
        setNewMapTopic={setNewMapTopic}
        {...props}
      />
    );
  };
}
