/**
 * Dumb page component that renders displayables specified in the config.
 *
 * A displayable is either a section, or a module. A section is a collection of modules.
 *
 * Displayables must be imported and added to the `modules` object before they can be rendered.
 *
 * The component is supposed to be rendered in a Bootstrap container.
 */

import React from "react";

import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import CardWithButton from "components/CardWithButton/CardWithButton";
import EISChaptersGroup from "components/ContentGroup/EISChaptersGroup";
import GalleryGroup from "components/ContentGroup/GalleryGroup";
import FrequentlyAskedQuestions from "components/FrequentlyAskedQuestions/FrequentlyAskedQuestions";
import Gallery from "components/Gallery/Gallery";
import HeroImage from "components/Heroes/HeroImage";
import HeroVideo from "components/Heroes/HeroVideo";
import MapPage from "components/InteractiveMap/MapPage";
import CardImageLink from "components/Links/CardImageLink";
import ImageLink from "components/Links/ImageLink";
import BaseMarkdownRenderer from "components/Markdown/BaseMarkdownRenderer";
import FirstTimeModal from "components/Modals/FirstTimeModal";
import PDFViewer from "components/PDFViewer/PDFViewer";
import SectionHeading from "components/Sections/SectionHeading";
import SectionHeadingWithButtons from "components/Sections/SectionHeadingWithButtons";
import SectionText from "components/Sections/SectionText";
import VirtualConsultationRoom from "components/VirtualConsultationRoom/VirtualConsultationRoom";

const modules = {
  BaseMarkdownRenderer,
  CardImageLink,
  CardWithButton,
  EISChaptersGroup,
  FirstTimeModal,
  FrequentlyAskedQuestions,
  Gallery,
  GalleryGroup,
  HeroImage,
  HeroVideo,
  ImageLink,
  MapPage,
  PDFViewer,
  SectionHeading,
  SectionHeadingWithButtons,
  SectionText,
  VirtualConsultationRoom,
};

/**
 * Wrap the child modules in a Container and Row when `wrapWithContainer` is true.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ContainerWrapper = (props) => {
  return (
    <Container>
      <Row>{props.children}</Row>
    </Container>
  );
};

const hydrateModuleFromConfig = (moduleConfig) => {
  const Displayable = modules[moduleConfig.moduleComponentName];
  return (
    <Col key={`${moduleConfig.name}-col`} {...moduleConfig.columnProps}>
      <Displayable key={moduleConfig.name} {...moduleConfig.moduleProps} />
    </Col>
  );
};

const Page = ({ config }) => {
  const children = config.map((displayableConfig) => {
    let columns;

    if (displayableConfig.children) {
      // This displayable is a section.
      columns = displayableConfig.children.map(hydrateModuleFromConfig);
    } else {
      // This displayable is a module.
      columns = [hydrateModuleFromConfig(displayableConfig)];
    }

    const Wrapper = displayableConfig.wrapWithContainer
      ? ContainerWrapper
      : React.Fragment;

    return (
      <Row
        key={`${displayableConfig.name}-row`}
        {...displayableConfig.rowProps}
      >
        <Wrapper>{columns}</Wrapper>
      </Row>
    );
  });

  return <>{children}</>;
};

// Required property shapes for modules and sections.
const modulePropShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  moduleComponentName: PropTypes.string.isRequired,
  columnProps: PropTypes.object,
  moduleProps: PropTypes.object,
  rowProps: PropTypes.object,
  wrapWithContainer: PropTypes.bool,
});
const sectionPropShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(modulePropShape),
  rowProps: PropTypes.object,
  wrapWithContainer: PropTypes.bool,
});

Page.propTypes = {
  config: PropTypes.arrayOf(sectionPropShape, modulePropShape).isRequired,
};

export default Page;
