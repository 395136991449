/**
 * The HeroVideo component is similar in concept to the HeroImage component.
 * give a link to the video source(s) as props, and optionally a css gradient to show during loading
 */

import React from "react";

import { graphql, StaticQuery } from "gatsby";
import PropTypes from "prop-types";

const withVideos = (WrappedComponent) => {
  return function WrappedWithVideos(props) {
    return (
      <StaticQuery
        query={graphql`
          query Videos {
            videos: allFile(
              filter: {
                extension: { regex: "/(mp4|webm)/" }
                sourceInstanceName: { eq: "videos" }
              }
            ) {
              edges {
                node {
                  id
                  publicURL
                  name
                  extension
                }
              }
            }
          }
        `}
        render={(data) => <WrappedComponent {...props} videos={data} />}
      />
    );
  };
};

const getVideoType = (extension) => {
  const typeMap = { mp4: "video/mp4", webm: "video/webm" };
  return typeMap[extension] || "video/*";
};

const HeroVideo = withVideos((props) => {
  const video = props.videos.videos.edges.find(({ node }) => {
    return node.name === props.videoName;
  });
  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video autoPlay muted poster loop className="hero-video">
      <source
        src={video.node.publicURL}
        type={getVideoType(video.node.extension)}
      />
    </video>
  );
});

HeroVideo.propTypes = {
  videoName: PropTypes.string,
};

export default HeroVideo;
