import React, { createContext, useReducer } from "react";

import PropTypes from "prop-types";

import { mapConfig } from "src/app-config";

export const MapContext = createContext();

const initialState = {
  map: {},
  legend: { common: mapConfig.commonLegend, activeLayer: [] },
  loaded: false,
  geocoderCreated: false,
  userSelectedLayers: [],
  mapResetTrigger: 0,
};

export const types = {
  SET_MAP: "SET_MAP",
  SET_MAP_LEGEND: "SET_MAP_LEGEND",
  SET_MAP_LOADED: "SET_MAP_LOADED",
  SET_GEOCODER_CREATED: "SET_GEOCODER_CREATED",
  ADD_USER_SELECTED_LAYERS: "ADD_USER_SELECTED_LAYERS",
  REMOVE_USER_SELECTED_LAYERS: "REMOVE_USER_SELECTED_LAYERS",
  RESET_MAP: "RESET_MAP",
};

const reducer = (state, action) => {
  switch (action.type) {
    case types.SET_MAP:
      return {
        ...state,
        map: action.payload,
      };
    case types.SET_MAP_LEGEND:
      return {
        ...state,
        legend: {
          ...state.legend,
          activeLayer: action.payload,
        },
      };
    case types.SET_MAP_LOADED:
      return {
        ...state,
        loaded: true,
      };
    case types.SET_GEOCODER_CREATED:
      return {
        ...state,
        geocoderCreated: true,
      };
    case types.ADD_USER_SELECTED_LAYERS:
      return {
        ...state,
        userSelectedLayers: [...state.userSelectedLayers, ...action.payload],
      };
    case types.REMOVE_USER_SELECTED_LAYERS:
      return {
        ...state,
        userSelectedLayers: state.userSelectedLayers.filter((layer) => {
          return !action.payload.includes(layer);
        }),
      };
    case types.RESET_MAP:
      return {
        ...state,
        mapResetTrigger: state.mapResetTrigger + 1,
      };
    default:
      return state;
  }
};

export const MapContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <MapContext.Provider value={[state, dispatch]}>
      {props.children}
    </MapContext.Provider>
  );
};

MapContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
